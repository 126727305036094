import { useEffect, useRef, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { TRACK_SEARCH_INPUT } from "../../api/middleware/trackingMiddleware";

export const useClickOutsideNode = (onClickOutsideFn: Function) => {
    const nodeRef = useRef(null);

    const handleClickOutsideNode = useCallback((event) => {
        if (nodeRef.current.contains(event.target)) {
            return;
        }
        onClickOutsideFn();
    }, [onClickOutsideFn])

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOutsideNode);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideNode);
        };
    }, [handleClickOutsideNode]);

    return { nodeRef, handleClickOutsideNode };
}

export const useInfiniteScroll = (callback: Function, targetElementId?: string) => {
    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
        let isMounted = true;
        window.addEventListener('scroll', (event) => handleScroll(event, isMounted), true);
        return () => {
            isMounted = false;
            window.removeEventListener('scroll', (event) => handleScroll(event, isMounted));
        };
    }, []);

    useEffect(() => {
        if (!isBottom) {
            return;
        }
        if (callback) {
            callback();
        }
        setIsBottom(false);

    }, [isBottom]);

    const handleScroll = useCallback((event, isMounted) => {
        //TODO check if reach page bottom validation work in all served browsers
        const element = event.target;
        if (targetElementId && element.id !== targetElementId) {
            return;
        }
        /* typically the row is 48 px so adding it as a safety margin so the loading starts earlier*/
        const margin = 48;
        if ((element.offsetHeight + element.scrollTop + margin) >= element.scrollHeight && !isBottom && isMounted) {
            setIsBottom(true);
        }
    }, [isBottom, targetElementId]);
};

export const usePagedResult = (
    paginationModel: { items: any[], hasNextPage: boolean, nextPageToken?: string, skip?: number },
    filter: string,
    clearResultActionType: string,
    callback: Function,
    loading: boolean
): [Function, string, string] => {
    const dispatch = useDispatch();
    const [filterDebounced] = useDebounce(filter, 500);
    const [searchPhrase, setSearchPhrase] = useState("");
    const pageSize = 20;
    const { items, hasNextPage, nextPageToken, skip } = paginationModel;

    useEffect(() => {
        if (items && items.length) {
            dispatch({ type: clearResultActionType });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (items && items.length && searchPhrase !== filterDebounced) {
            dispatch({ type: clearResultActionType });
        }
    }, [items, searchPhrase, filterDebounced, dispatch, clearResultActionType]);

    useEffect(() => {
        if (filterDebounced.length >= 2 && searchPhrase !== filterDebounced) {
            setSearchPhrase(filterDebounced);
            dispatch({
                type: TRACK_SEARCH_INPUT
            });
            callback({
                pageSize,
                freeText: filterDebounced
            });
        }
    }, [dispatch, searchPhrase, filterDebounced, callback]);

    const loadMore = useCallback(() => {
        if (filterDebounced && hasNextPage && !loading) {
            callback({
                pageSize,
                freeText: filterDebounced,
                pageToken: nextPageToken,
                skip
            });
        }
    }, [hasNextPage, nextPageToken, filterDebounced, pageSize, skip, loading, callback]);

    return [loadMore, filterDebounced, searchPhrase];
};

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};
