import styled from "styled-components";

enum Breakpoints {
    XL = "1920px",
    L = "1439px",
    M = "1227px",
    S = "1024px",
    XS = "767px"
}

export const Background = styled.div`
    min-width: 100vw;
    min-height: 100vh;
    color: #f7f7f7;
    background-color: #011523;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 50px 28px;

    @media (max-width: ${Breakpoints.XS}) {
        padding: 0;
        height: fit-content;
    }
`;

export const Container = styled.div`
    display: flex;
    gap: 32px;

    @media (max-width: ${Breakpoints.XS}) {
        flex-direction: column;
        gap: 24px;
    }
`;

export const Image = styled.img`
    width: 50%;
    max-width: 600px;
    object-fit: cover;

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
        max-width: unset;
    }
`;

export const Content = styled.div`
    width: 50%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
        max-width: unset;
        padding: 0 20px 53px;
        justify-content: center;
        text-align: center;
        gap: 24px;
    }
`;

export const H1 = styled.h1`
    font-size: 32px;
    margin: 0 0 24px;

    @media (max-width: ${Breakpoints.M}) {
        font-size: 28px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        font-size: 24px;
        text-align: center;
        width: 100%;
    }
`;

export const Text = styled.p<{ bold?: boolean }>`
    ${({ bold }) => bold && "font-weight: bold;"}
    font-size: 16px;
    margin: 0;

    @media (max-width: ${Breakpoints.M}) {
        font-size: 15px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        font-size: 14px;
    }
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 24px;
    margin: 24px 0;
`;

export const Item = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    width: 100%;

    svg {
        flex: 0 0 20px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        flex-direction: column;
        justify-content: center;
        gap: 16px;
    }
`;

export const ItemText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 8px;

    @media (max-width: ${Breakpoints.XS}) {
        align-items: center;
    }
`;

export const Link = styled.a`
    border-radius: 24px;
    background-color: #9977fe;
    padding: 12px 48px;
    line-height: 24px;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: #f7f7f7;

    @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
        margin-bottom: 50px;
    }

    @media (hover: hover) and (pointer: fine) {
        :hover {
            background-color: #8d5fff;
        }
    }

    :active {
        background-color: #6c47cf;
    }
`;

export const LogoWrapper = styled.div`
    position: absolute;
    bottom: 16px;
    left: 40px;

    @media (max-width: ${Breakpoints.XS}) {
        left: 16px;
    }
`;
