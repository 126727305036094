import React from "react";
import { ReactComponent as CheckIcon } from "../../assets/icons/check_24px_rounded.svg";
import { ReactComponent as Logo } from "../../assets/icons/securitas-logo-1.svg";
import ReplacementImageXS from "../../assets/images/replacement-xs.png";
import ReplacementImage from "../../assets/images/replacement.png";
import { useWindowSize } from "../../store/shared/CommonHooks";
import { Background, Container, Content, H1, Image, Item, ItemText, Link, List, LogoWrapper, Text } from "./MySecuritasPage.styles";

const listItems = [
    {
        title: "Rich User Management",
        description: "You can invite multiple people to multiple locations through a guided process."
    },
    {
        title: "Invitation tracking",
        description: "You can see the status of your invitations and take action if your clients are not activating their account."
    },
    {
        title: "Bulk invitation",
        description: "Through which you can onboard multiple clients based on CSV files that you can edit in Excel or other spreadsheet programs."
    },
    {
        title: "Client switcher",
        description: "Use it to select a client you have access to and see mobile or on-site guarding the same way your clients do."
    },
    {
        title: "Mobile application",
        description: "You can perform any activities on the go with the full access"
    }
];

const MySecuritasPage: React.FC = () => {
    const size = useWindowSize();
    const isMobile = size[0] <= 767;

    return (
        <Background>
            <Container>
                <Image alt="" src={isMobile ? ReplacementImageXS : ReplacementImage} />
                <Content>
                    <H1>Welcome to MySecuritas</H1>
                    <Text>
                        Back-office functionality previously found in MySecuritas for Operations (MSO) is now performed in the standard MySecuritas
                        application. You are able to log in to MySecuritas using the same user account as before, your roles and permissions are
                        unchanged and applies as before. By moving to MySecuritas you get the following benefits:
                    </Text>
                    <List>
                        {listItems.map(({ title, description }) => (
                            <Item key={title}>
                                <CheckIcon />
                                <ItemText>
                                    <Text bold>{title}</Text>
                                    <Text>{description}</Text>
                                </ItemText>
                            </Item>
                        ))}
                    </List>
                    <Link href="https://web.mysecuritas.com/">Head over to MySecuritas</Link>
                </Content>
            </Container>
            <LogoWrapper>
                <Logo />
            </LogoWrapper>
        </Background>
    );
};

export default MySecuritasPage;
