import i18next from "i18next";
import { IAuthorization } from "../../authorization/models/AuthorizationModel";
import { ICustomer } from "../../customers/models/CustomerModel";
import { ICustomerObject } from "../../customerObjects/models/CustomerObjectModel";
import { ILocation } from "../../organization/models/LocationModel";

interface IUserName {
    first: string;
    middle: string;
    last: string;
}

interface IUserEmail {
    address: string;
    isVerified: boolean;
    isPrimary: boolean;
}

interface IUserPhone {
    number: string;
    isPrimary: boolean;
}

interface IUserDevice {
    deviceId: string;
}

export interface IUserPreference {
    key: string;
    value: string;
}

export interface IUserAttribute {
    key: string;
    value: string | Date;
}

export interface IUser {
    id: string;
    status: string;
    isEmployee: boolean;
    name: IUserName;
    fullName?: string;
    emails: IUserEmail[];
    emailPrimary?: string;
    phones: IUserPhone[];
    phonePrimary?: string;
    devices: IUserDevice[];
    preferences: IUserPreference[];
    attributes: IUserAttribute[];
    role?: string;
    userType?: string
    //custom parameters
    canRevokeAllAccess?: boolean;
    canRemoveUser?: boolean;
    canResendInvitation?: boolean;
}

export interface IUserSearchResult {
    createdTime?: string;
    id: string;
    isEmployee: boolean;
    name?: string;
    primaryEmailAddress: string;
    primaryPhoneNumber?: string;
    status: string;
}

export type IResource = ICustomer | ICustomerObject | ILocation;

export interface IUserResource {
    location: IResource;
    authorizations: IAuthorization[];
}

export function getUserPrimaryEmail(emails: IUserEmail[]) {
    if (emails && emails.length) {
        let primaryEmail = emails.find(email => email.isPrimary);
        if (!primaryEmail) {
            primaryEmail = emails[0];
        }
        return primaryEmail.address;
    }
}

export function getUserPrimaryPhone(phones: IUserPhone[]) {
    if (phones && phones.length) {
        let primaryPhone = phones.find(phone => phone.isPrimary);
        if (!primaryPhone) {
            primaryPhone = phones[0];
        }
        return primaryPhone.number;
    }
}

export function getUserRole(attributes) {
    if (attributes && attributes.length) {
        const roleAttribute = attributes.find(a => a.key === "role");
        if (roleAttribute) {
            return roleAttribute.value;
        }
    }
}

export function getUserFullName(name: IUserName) {
    let combinedName = null;
    if (name) {
        combinedName = name.first;
        if (name.middle) {
            combinedName += " " + name.middle;
        }
        if (name.last) {
            combinedName += " " + name.last;
        }
    }
    return combinedName;
}

export function getUserStatusTranslated(status: string): string {
    const translationPath = `userStatuses.${status}`;
    if (i18next.exists(translationPath)) {
        return i18next.t(translationPath);
    }

    return status;
}

export function mapToUser(userDTO: any): IUser {
    return {
        id: userDTO.id,
        status: userDTO.Status ? userDTO.Status : userDTO.status,
        isEmployee: userDTO.isEmployee,
        name: userDTO.name,
        fullName: getUserFullName(userDTO.name),
        emails: userDTO.emails,
        emailPrimary: userDTO.emails ? getUserPrimaryEmail(userDTO.emails) : userDTO.primaryEmailAddress,
        phones: userDTO.phones,
        phonePrimary: userDTO.phones ?  getUserPrimaryPhone(userDTO.phones): userDTO.primaryPhoneNumber,
        devices: userDTO.devices,
        preferences: userDTO.preferences,
        attributes: userDTO.attributes,
        role: getUserRole(userDTO.attributes),
        userType: userDTO.userType
    };
}

export function mapAuthGraphUser(userDTO: any): IUserSearchResult {
    return {

        id: userDTO.id,
        status: userDTO.status ?? "",
        isEmployee: null,
        name: userDTO.name ?? "",
        primaryEmailAddress: userDTO.email ?? "",
        primaryPhoneNumber: userDTO.phone ?? ""
       
    };
}

export const msoActivationLinkSentAt = "msoActivationLinkSentAt";
export const mysBasicActivationLinkSentAt = "mysBasicActivationLinkSentAt";
