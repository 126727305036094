import { createGlobalStyle } from "styled-components";

// tell webpack to package fonts into build folder

import NotoSansRegular from "../assets/fonts/noto/NotoSans-Regular.ttf";
import NotoSansItalic from "../assets/fonts/noto/NotoSans-Italic.ttf";
import NotoSansBold from "../assets/fonts/noto/NotoSans-Bold.ttf";
import NotoSansBoldItalic from "../assets/fonts/noto/NotoSans-BoldItalic.ttf";

// See this link for font-display: fallback behavior
// https://www.styled-components.com/docs/faqs#how-do-i-fix-flickering-text-after-server-side-rendering

const GlobalStyles = createGlobalStyle`
    :root {
        /* colors */
        --securitas-black-15: #BBBDBF;
        --securitas-black-45: #9C9EA1;
        --securitas-black-75: #021D2D; // changed value to navy in Jan 2021
        --securitas-blue: #2DABE2;
        --securitas-blue-active: #97CEE7;
        --securitas-blue-hover: #6EBDE9;
        --securitas-blue-inactive: #BDDCEB;
        --securitas-blue-light: #F2F8FB; /* securitas-blue-inactive with opacity/alpha channel */
        --securitas-green: #61BD4F;

        --silver: #EBEBEB; //=== securitas-grey-1  === light gray tertialy default 100
        --securitas-grey-2: #EFEFEF;
        --securitas-grey-3: #F5F5F5;
        --securitas-grey-4: #FAFAFA;

        --securitas-orange: #FFAB4A;
        --securitas-red: #EB5A46;
        --securitas-red-60: #F09797;
        --securitas-white: #FFFFFF;

        /* default shadows and radius */
        --securitas-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
        --securitas-border-radius: 4px;
        --modal-border-radius: 8px;

        --securitas-separator-border: 1px solid var(--silver);

        /* default spacing */
        --top-layer-height: 128px;

    }

    @font-face {
        font-family: 'NotoSans';
        src: url('${NotoSansRegular}') format('opentype');
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
        letter-spacing: 0.1px;
    }

    @font-face {
        font-family: 'NotoSans';
        src: url('${NotoSansItalic}}') format('opentype');
        font-weight: normal;
        font-style: italic;
        font-display: fallback;
        letter-spacing: 0.1px;
    }

    @font-face {
        font-family: 'NotoSans';
        src: url('${NotoSansBold}') format('opentype');
        font-weight: bold;
        font-style: normal;
        font-display: fallback;
        letter-spacing: 0.1px;
    }
    @font-face {
        font-family: 'NotoSans';
        src: url('${NotoSansBoldItalic}') format('opentype');
        font-weight: bold;
        font-style: italic;
        font-display: fallback;
        letter-spacing: 0.1px;
    }

    * {
        box-sizing: border-box;
    }

    /* scrollbar */
    /* Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: var(--silver) transparent;
    }

    /* Chrome/Edge/Safari */
    *::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    *::-webkit-scrollbar-track {
        background: var(--securitas-white);
    }
    *::-webkit-scrollbar-thumb {
        background-color: var(--silver);
        border-radius: 8px;
    }
    /* scrollbar end */


    html, body {
        margin: 0;
        font-family: NotoSans, OpenSans, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: var(--securitas-black-75);
    }

    /* Allow the body to grow as high as the content in it requires. */
    html {
        height: 100%;
    }

    /* Force the body not to get any smaller than then window height. */
    body {
        min-height: 100%;
    }

    /* inherit the font in the buttons and other elements */
    input, textarea, button {
        font-family: inherit;
        color: inherit
    }
`;

export default GlobalStyles;
