import React from "react";
import MySecuritasPage from "./components/MySecuritasPage/MySecuritasPage";
import GlobalStyles from "./styles/GlobalStyles";

const App: React.FC = () => (
    <>
        <GlobalStyles />
        <MySecuritasPage />
    </>
);

export default App;
